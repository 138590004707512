import Address from "../apis/address";

const state = {
  addressList: [],
  deleteAddress: [],
  addAddress: {},
  primaryAddress: {},
};
const actions = {
  getAddress({ commit }, data) {
    return Address.addressList(data).then((response) => {
      if (response.data.code == 200) {
        commit("getAddress", response.data.Result);
      } else if (response.data.code == 101) {
        commit("getAddress", response.data.Result);
      }
      return response.data;
    });
  },

  removeAddress({ commit }, data) {
    return Address.deleteAddress(data).then((response) => {
      if (response.data.code == 200) {
        // commit("removeAddress", response.data.Result)
        return response.data;
      }
      return response.data;
    });
  },

  newAddress({ commit }, data) {
    return Address.addAddress(data).then((response) => {
      if (response.data.code == 200) {
        localStorage.setItem("latitude", response.data.Result.latitude);
        localStorage.setItem("longitude", response.data.Result.longitude);
        commit("newAddress", response.data.Result);
      }
      return response.data;
    });
  },

  updateNewAddress({ commit }, data) {
    return Address.updateAddress(data).then((response) => {
      if (response.data.code == 200) {
        //commit("updateNewAddress", response.data.Result)
        return response.data;
      }
      return response.data;
    });
  },

  getPrimaryAddress({ commit }, data) {
    return Address.primaryAddress(data).then((response) => {
      if (response.data.code == 200) {
        commit("primaryAddressMutation", response.data.Result);
      }
      return response.data;
    });
  },

  setPrimaryAddress({ commit }, data) {
    return Address.setPrimaryAddress(data).then((response) => {
      if (response.data.code == 200) {
        return response.data;
      }
      return response.data;
    });
  },

  getUserNearAddressData({ commit }, data) {
    return Address.getUserAddress(data).then((response) => {
      if (response.data.code == 200) {
        commit("primaryAddressMutation", response.data.Result);
      }
      return response.data;
    });
  },
};
const mutations = {
  getAddress(state, addressList) {
    state.addressList = addressList;
  },
  newAddress(state, addAddress) {
    state.addressList.push(addAddress);
    // state.addAddress = addAddress
  },
  primaryAddressMutation(state, primaryAddress) {
    state.primaryAddress = primaryAddress;
    if (Object.keys(primaryAddress).length > 0) {
      state.primaryAddress["deliveryAddress"] =
        primaryAddress.flat_no +
        " " +
        primaryAddress.house_name +
        " " +
        primaryAddress.Shiping_address;
    }
  },
};
const getters = {
  addressList: (state) => state.addressList,
  deleteAddress: (state) => state.deleteAddress,
  addAddress: (state) => state.addAddress,
  primaryAddress: (state) => state.primaryAddress,
};

export const address = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
